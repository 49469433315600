import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const AProposPage = (data) => {
   
  return (
    <Layout>
      <SEO 
        title="Biographie et parcours - Virginia Garrido Millan"
        description="Retrouvez la biographie de l'illustratrice et graphiste Virginia Garrido Millan, à travers sa Biographie. Découvrez ses prix et ses réalisations."
        author="Virginia"
      />
      <div className="l-apropos">
        <p className="l-apropos__title">¡Hola!</p>
        <div className="container">
            <div className="l-apropos__content">
                <h1 className="l-apropos__content__title">Virginia Garrido Millán</h1>
                <div className="l-apropos__content__wrapper">
                  <div class="text">
                  <p>J'exprime l'art du dessin et de la peinture en tant qu'illustratrice, principalement pour l'édition et la presse, et j'utilise également le corps humain comme médium artistique pour la pratique du tatouage.</p>

<p>J’ai étudié à l’École des Arts appliqués de Cordoue en Espagne, mon pays d'origine, et depuis 2008 j’ai illustré un grand nombre de livres et d'albums publiés aux éditions Eyrolles, Fleurus et Edimater en France, en Espagne et en Amérique du Sud.</p>

<p>Entre 2005 et 2007, j'ai reçu plusieurs récompenses pour mon travail sur des livres, sur un projet d’arts plastiques et de design et pour l'une de mes peintures. On peut retrouver ma biographie complète dans l'ouvrage “Creando Cultura, Doce villarenses y las artes plásticas” de l'historienne María Navajas Madueño.</p>

<p>Aujourd'hui, je vis en France, à Bordeaux. Je suis artiste indépendant et je suis représentée par ma très chère <a target="_blank" href="http://www.patricia-lucas.com/">Patricia Lucas, et son agence d'illustrateurs à Paris</a>, pour l'ensemble de mon travail d'artiste illustratrice. J'exerce également la pratique du tatouage dans mon atelier et dans plusieurs salons en France et à l'étranger. J'espère vous y rencontrer un jour !

</p>
                  </div>
                  
                </div>
            </div>
            <div className="l-apropos__picture">
                <Img fluid={data.data.virginia.childImageSharp.fluid} />
            </div>
        </div>
        
      </div>
    </Layout>
  )
}
  
export default AProposPage
 
export const query = graphql`
  query {
    virginia:file(relativePath: { eq: "apropos/virginia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  },
`  